"use strict";

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.from.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/es.symbol.js");

require("core-js/modules/es.symbol.description.js");

require("core-js/modules/es.symbol.iterator.js");

require("core-js/modules/es.array.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.get-own-property-descriptor.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.get-own-property-descriptors.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anyAssetFilter = anyAssetFilter;
exports.allAssetFilter = allAssetFilter;
exports.anyTagsFilter = anyTagsFilter;
exports.roundwareDefaultFilterChain = exports.pausedAssetFilter = exports.dateRangeFilter = exports.timedRepeatFilter = exports.assetShapeFilter = exports.timedAssetFilter = exports.distanceRangesFilter = exports.distanceFixedFilter = exports.ASSET_PRIORITIES = void 0;

require("core-js/modules/es.object.freeze.js");

require("core-js/modules/es.object.assign.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _distance3 = _interopRequireDefault(require("@turf/distance"));

var _booleanPointInPolygon = _interopRequireDefault(require("@turf/boolean-point-in-polygon"));

var _utils = require("./utils");

var _mixer = require("./mixer");

var _app = require("./errors/app.errors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var ASSET_PRIORITIES = Object.freeze({
  DISCARD: false,
  NEUTRAL: 0,
  LOWEST: 1,
  NORMAL: 100,
  HIGHEST: 999
});
exports.ASSET_PRIORITIES = ASSET_PRIORITIES;

var alwaysLowest = function alwaysLowest() {
  return ASSET_PRIORITIES.LOWEST;
};

var alwaysNeutral = function alwaysNeutral() {
  return ASSET_PRIORITIES.NEUTRAL;
}; // eslint-disable-line no-unused-vars

/**
 *Accept an asset if any one of the provided filters passes, returns the first non-discarded and non-neutral rank
 *
 * @export
 * @param {(Array<(asset: IDecoratedAsset, param?: IMixParams | any) => number>)} [filters=[]]
 * @param {IMixParams} [mixParams]
 * @return {*}
 */


function anyAssetFilter() {
  var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var mixParams = arguments.length > 1 ? arguments[1] : undefined;
  if ((0, _utils.isEmpty)(filters)) return alwaysLowest;
  return function (asset, _ref) {
    var stateParams = Object.assign({}, _ref);

    var _iterator = _createForOfIteratorHelper(filters),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var filter = _step.value;
        var rank = filter(asset, _objectSpread(_objectSpread({}, mixParams), stateParams));

        if ( // @ts-ignore
        rank !== ASSET_PRIORITIES.DISCARD && rank !== ASSET_PRIORITIES.NEUTRAL) {
          return rank;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (asset.status === "paused") console.log("Discarded from anyAssetFilter");
    return ASSET_PRIORITIES.DISCARD;
  };
}
/** Filter composed of multiple inner filters that accepts assets which pass every inner filter. */


function allAssetFilter() {
  var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var mixParams = arguments.length > 1 ? arguments[1] : undefined;
  if ((0, _utils.isEmpty)(filters)) return alwaysLowest;
  return function (asset, _ref2) {
    var stateParams = Object.assign({}, _ref2);
    var ranks = [];

    var _iterator2 = _createForOfIteratorHelper(filters),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var filter = _step2.value;
        var rank = filter(asset, _objectSpread(_objectSpread({}, mixParams), stateParams)); // @ts-ignore

        if (rank === ASSET_PRIORITIES.DISCARD) return rank; // can skip remaining filters

        ranks.push(rank);
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    var finalRank = ranks.find(function (r) {
      return r !== ASSET_PRIORITIES.NEUTRAL;
    }) || ranks[0];
    return finalRank;
  };
} // a "pre-filter" used by geo-enabled filters to make sure if we are missing data, or geoListenMode is DISABLED,
// we always return a neutral ranking


var rankForGeofilteringEligibility = function rankForGeofilteringEligibility(asset, _ref3) {
  var listenerPoint = _ref3.listenerPoint,
      geoListenMode = _ref3.geoListenMode;
  return geoListenMode !== _mixer.GeoListenMode.DISABLED && listenerPoint && asset;
};

var calculateDistanceInMeters = function calculateDistanceInMeters(loc1, loc2) {
  return (0, _distance3.default)(loc1, loc2, {
    units: "meters"
  });
};
/** Only accepts an asset if the user is within the project-configured recording radius  */


var distanceFixedFilter = function distanceFixedFilter() {
  return function (asset, options) {
    try {
      if (options.geoListenMode === _mixer.GeoListenMode.DISABLED) {
        return ASSET_PRIORITIES.LOWEST;
      }

      if (!rankForGeofilteringEligibility(asset, options)) return ASSET_PRIORITIES.NEUTRAL;
      var assetLocationPoint = asset.locationPoint;
      var listenerPoint = options.listenerPoint,
          recordingRadius = options.recordingRadius;
      if (typeof listenerPoint == "undefined") throw new _app.RoundwareFrameworkError("listenerPoint was undefined");
      if (typeof assetLocationPoint == "undefined") throw new _app.RoundwareFrameworkError("assetLocationPoint was undefined");

      var _distance = calculateDistanceInMeters(listenerPoint, assetLocationPoint);

      if (_distance < recordingRadius) {
        return ASSET_PRIORITIES.NORMAL;
      } else {
        if (asset.status === "paused") console.log("Discarded from distanceFixedFilter");
        return ASSET_PRIORITIES.DISCARD;
      }
    } catch (e) {
      throw new _app.RoundwareFrameworkError("Something went wrong!");
    }
  };
};
/**
 Accepts an asset if the user is within range of it based on the current dynamic distance range.
 */


exports.distanceFixedFilter = distanceFixedFilter;

var distanceRangesFilter = function distanceRangesFilter() {
  return function (asset, options) {
    try {
      if (options.getListenMode === _mixer.GeoListenMode.DISABLED) {
        return ASSET_PRIORITIES.LOWEST;
      }

      if (!rankForGeofilteringEligibility(asset, options)) {
        return ASSET_PRIORITIES.NEUTRAL;
      }

      var listenerPoint = options.listenerPoint,
          _options$minDist = options.minDist,
          minDist = _options$minDist === void 0 ? 0 : _options$minDist,
          maxDist = options.maxDist;

      if (minDist === undefined || maxDist === undefined) {
        return ASSET_PRIORITIES.NEUTRAL;
      }

      var locationPoint = asset.locationPoint;
      if (typeof locationPoint == "undefined" || typeof listenerPoint == "undefined") throw new _app.RoundwareFrameworkError("locationPoint is undefined!");

      var _distance2 = calculateDistanceInMeters(listenerPoint, locationPoint);

      if (_distance2 >= minDist && _distance2 <= maxDist) {
        return ASSET_PRIORITIES.NORMAL;
      } else {
        if (asset.status === "paused") console.log("Discarded from distanceRangesFilter");
        return ASSET_PRIORITIES.DISCARD;
      }
    } catch (e) {
      console.error(e);
      throw new _app.RoundwareFrameworkError("Something went wrong!");
    }
  };
}; // Rank the asset if it is tagged with one of the currently-enabled tag IDs


exports.distanceRangesFilter = distanceRangesFilter;

function anyTagsFilter() {
  return function (asset, mixParams) {
    var listenTagIds = mixParams.listenTagIds;
    if (!listenTagIds || (0, _utils.isEmpty)(listenTagIds)) return ASSET_PRIORITIES.LOWEST;
    var _asset$tag_ids = asset.tag_ids,
        assetTagIds = _asset$tag_ids === void 0 ? [] : _asset$tag_ids;

    var _iterator3 = _createForOfIteratorHelper(assetTagIds),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var tagId = _step3.value;
        if (listenTagIds.includes(tagId)) return ASSET_PRIORITIES.LOWEST; // matching only by tag should be the least-important filter
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }

    if (asset.status === "paused") console.log("Discarded from anyTagsFilter");
    return ASSET_PRIORITIES.DISCARD;
  };
} // keep assets that are slated to start now or in the past few minutes AND haven't been played before


var timedAssetFilter = function timedAssetFilter() {
  return function (asset, _ref4) {
    var _ref4$elapsedSeconds = _ref4.elapsedSeconds,
        elapsedSeconds = _ref4$elapsedSeconds === void 0 ? 0 : _ref4$elapsedSeconds,
        _ref4$timedAssetPrior = _ref4.timedAssetPriority,
        timedAssetPriority = _ref4$timedAssetPrior === void 0 ? "normal" : _ref4$timedAssetPrior;
    var timedAssetStart = asset.timedAssetStart,
        timedAssetEnd = asset.timedAssetEnd,
        playCount = asset.playCount;
    if (!timedAssetStart || !timedAssetEnd) return ASSET_PRIORITIES.DISCARD;

    if (timedAssetStart >= elapsedSeconds || timedAssetEnd <= elapsedSeconds || playCount > 0) {
      if (asset.status === "paused") console.log("Discarded from timedAssetFilter");
      return ASSET_PRIORITIES.DISCARD;
    }

    var priorityEnumStr = timedAssetPriority.toUpperCase(); // "highest", "lowest", "normal", etc.

    if (priorityEnumStr in ASSET_PRIORITIES) return ASSET_PRIORITIES[priorityEnumStr];
    return ASSET_PRIORITIES.NEUTRAL;
  };
}; // Accept an asset if the user is currently within its defined shape


exports.timedAssetFilter = timedAssetFilter;

var assetShapeFilter = function assetShapeFilter() {
  return function (asset, options) {
    var shape = asset.shape;
    if (!(shape && rankForGeofilteringEligibility(asset, options))) return ASSET_PRIORITIES.NEUTRAL;
    var listenerPoint = options.listenerPoint;
    if (!listenerPoint) throw new _app.RoundwareFrameworkError("listenerPoint was undefined");

    if ((0, _booleanPointInPolygon.default)(listenerPoint, shape)) {
      return ASSET_PRIORITIES.NORMAL;
    } else {
      if (asset.status === "paused") console.log("Discarded from assetShapeFilter");
      return ASSET_PRIORITIES.DISCARD;
    }
  };
}; // Prevents assets from repeating until a certain time threshold has passed


exports.assetShapeFilter = assetShapeFilter;

var timedRepeatFilter = function timedRepeatFilter() {
  return function (asset, _ref5) {
    var _ref5$bannedDuration = _ref5.bannedDuration,
        bannedDuration = _ref5$bannedDuration === void 0 ? 600 : _ref5$bannedDuration;
    var lastListenTime = asset.lastListenTime;
    if (!lastListenTime || (asset === null || asset === void 0 ? void 0 : asset.status) === "paused") return ASSET_PRIORITIES.NORMAL; // e.g. asset has never been heard before

    var durationSinceLastListen = (new Date().getTime() - new Date(lastListenTime).getTime()) / 1000;

    if (durationSinceLastListen <= bannedDuration) {
      return ASSET_PRIORITIES.DISCARD;
    } else {
      return ASSET_PRIORITIES.LOWEST;
    }
  };
};

exports.timedRepeatFilter = timedRepeatFilter;

var dateRangeFilter = function dateRangeFilter() {
  return function (asset, _ref6) {
    var startDate = _ref6.startDate,
        endDate = _ref6.endDate;

    if (startDate || endDate) {
      return (!startDate || asset.created >= startDate) && (!endDate || asset.created <= endDate) ? ASSET_PRIORITIES.NORMAL : ASSET_PRIORITIES.DISCARD;
    } else {
      return ASSET_PRIORITIES.LOWEST;
    }
  };
};

exports.dateRangeFilter = dateRangeFilter;

var pausedAssetFilter = function pausedAssetFilter() {
  return function (asset, mixParams) {
    if (asset.status === "paused") {
      console.log("Resuming asset ".concat(asset.id));
      asset.status = "resumed";
      return ASSET_PRIORITIES.HIGHEST;
    }

    return ASSET_PRIORITIES.NORMAL;
  };
};

exports.pausedAssetFilter = pausedAssetFilter;
var roundwareDefaultFilterChain = allAssetFilter([// @ts-ignore
anyAssetFilter([// @ts-ignore
timedAssetFilter(), // if an asset is scheduled to play right now, or
// @ts-ignore
assetShapeFilter(), // if an asset has a shape and we AREN'T in it, reject entirely, or
allAssetFilter([// @ts-ignore
distanceFixedFilter(), // if it has no shape, consider a fixed distance from it, or
distanceRangesFilter() //angleFilter() // if the listener is within a user-configured distance or angle range
])]), // @ts-ignore
timedRepeatFilter(), // only repeat assets if there's no other choice
//blockedAssetsFilter(), // skip blocked assets and users
anyTagsFilter(), // all the tags on an asset must be in our list of tags to listen for
// @ts-ignore
dateRangeFilter(), pausedAssetFilter() // if any asset was paused due to out of range it will be returned first
//trackTagsFilter(),     // if any track-level tag filters exist, apply them
//dynamicTagFilter("_ten_most_recent_days",mostRecentFilter({ days: 10 })) // Only pass assets created within the most recent 10 days
]);
exports.roundwareDefaultFilterChain = roundwareDefaultFilterChain;