import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ActionButton from './ActionButton';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRoundware } from '../../hooks';
import Container from '@material-ui/core/Container';
import AudioPlayer from '../AudioPlayer';

import useStyles from './styles';

export const LandingPage = () => {
	const { roundware } = useRoundware();
	const classes = useStyles();


	if (!roundware.project || roundware.project.projectName === '(unknown)') {
		return null;
	}
	return (
		<Container style={{ paddingRight: 0, paddingLeft: 0}}>
			<Grid container className={classes.landingHeader}>
				<Grid container justifyContent='center' style={{ height: '40px' }}>
					<Grid item sm={12}>
						<Typography variant={'h5'} className={classes.landingTagline} style={{ textAlign: 'center', height: '10vh' }}>
							Explore the relationship between mental health, language, and lineage.
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent='center' style={{ height: '20px' }}>
					<Grid item>
						<ActionButton label={'Begin'} linkTo={'/listen'} style={{ width: '100%' }} />
					</Grid>
				</Grid>
				<Grid container justifyContent='center' style={{ height: '100px' }}>
					<Grid item style={{ margin: 'auto', height: '5vh', textAlign: 'center', paddingTop: 0 }} sm={12}>
					</Grid>
					<Grid item sm={12}>
						<Typography variant={'h5'} className={classes.landingTagline} style={{ textAlign: 'center', height: '5vh' }}>
							Traditional xʷməθkwəy̓əm (Musqueam) Welcome
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent='center'>
					<Grid item>
						<AudioPlayer />
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
