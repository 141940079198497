import { useRoundware } from '../hooks';
import React, { Fragment, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

const InfoPopup = () => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div style={{ position: 'absolute', right: 10 }}>
			<Button onClick={handleClickOpen}>INFO</Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Szepty/Whispers</DialogTitle>
				<DialogContent dividers>
					<Typography variant={'h6'} gutterBottom>
						The Work:
					</Typography>
					<Typography gutterBottom> Szepty/Whispers: Dialogue is a digital experience using a web app and QR codes. Encounter “Whispers” (audio and text contributions from various artists) as you wander in-between different sites on unceded Musqueam, Squamish, and Tsleil-Waututh territories (in so-called downtown Vancouver). Delve into a fragmented, non-linear journey that explores the relationship between mental health, language, and lineage. Listen, read, contribute and join the conversation. Szepty/Whispers: Dialogue parallels an in-person performance in development.</Typography>
					<Typography variant={'h6'} gutterBottom>
						<br />
						The Team:
					</Typography>
					<Typography gutterBottom>
					Conceptualized by Veronique West, the work features contributions from mia susan amir, Kagan Goh, Maya Jones, Constantin Lozitsky, Jivesh Parasram, Kendra Place, and Manuel Axel Strain. It is developed in collaboration with The Cultch Digital Storytelling Team (Kris Boyd and David Mesiha), Sound Designer David Mesiha, Inclusive Designer JD Derbyshire, and Dramaturg Kathleen Flaherty.
					</Typography>
					<Typography variant={'h6'} gutterBottom>
						<br />
						We recognize the support of:
					</Typography>
					<Typography gutterBottom>Canadian Heritage, The Canada Council for The Arts, BC Arts Council, The Province of BC, The Cultch, Playwrights Theatre Centre, and Roundware.</Typography>
					{/* <Typography gutterBottom>
						You can check out codebases for the server and various frameworks on our&nbsp;
						<Link href='https://github.com/roundware'>GitHub page</Link>.
					</Typography> */}

					{/*<a href="./listen">
            <img id="map" src={assetMapGraphic} style={{width: "100%"}} />
          </a>
          <hr />*/}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='secondary' autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default InfoPopup;
