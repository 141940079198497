import React, { CSSProperties } from 'react';


interface AudioPlayerProps {
	style?: CSSProperties;
	className?: string;
	
}

const AudioPlayer = ({ style, className }: AudioPlayerProps) => {
	


	return (
		<audio controls style={style} preload='none' controlsList='nodownload' className={className} crossOrigin="anonymous">
			<source src="https://files.szepty.ca/audio/Music/Szepty-App-TraditionalWelcome.mp3"  />
			Your browser does not support audio!
		</audio>
	);
};

export default AudioPlayer;
