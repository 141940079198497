import { useRoundware } from '../hooks';
import React, { Fragment, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

const InstructionPopup = () => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div style={{ position: 'absolute', left: 10 }}>
			<Button onClick={handleClickOpen}>HOW-TO</Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
				<DialogTitle id="alert-dialog-title" onClose={handleClose}>
          {"Szepty/Whispers"}
        </DialogTitle>
			<DialogContent dividers>
				<Typography variant={"h6"} gutterBottom>
					To Listen:
				</Typography>
				<Typography gutterBottom>
					Press the travelling mode (person icon) button at the bottom left. Travelling mode will play audio based on your current location. Wander as you wish within the designated area, and discover the whispered stories.   
				</Typography>

				<Divider />
				<Typography variant={"h6"} gutterBottom>
					<br />To Share your own whispers:
				</Typography>
				<Typography gutterBottom>
                        Press the microphone button to anonymously record your own contribution for others to hear. 

				</Typography>
				<Typography variant={"h6"} gutterBottom>
					<br />Having trouble with this app?
				</Typography>
				<Typography gutterBottom>
					Please contact: PTC Audience Services community@playwrightstheatre.com 

				</Typography>

				{/* <Typography gutterBottom>
					You can check out codebases for the server and various frameworks
					on our&nbsp;
					<Link href="https://github.com/roundware">
						GitHub page
					</Link>.
					</Typography> */}

				{/*<a href="./listen">
					<img id="map" src={assetMapGraphic} style={{width: "100%"}} />
				</a>
				<hr />*/}
			</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color='secondary' autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default InstructionPopup;
